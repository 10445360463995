import { CircularProgress } from "@mui/material"
import useMeli from '../../../../hooks/useMeli'
import { useStoreContext } from "../../../../contexts/StoreContext"

import DeleteIntegration from "./DeleteIntegration"
import MakeIntegration from "./MakeIntegration"

function Configurations () {
  
  const store = useStoreContext()
  const {integration} = useMeli({ store_id: store.id })

  if (integration === undefined || integration === null) {
    return <CircularProgress />
  }

  if (integration === false) {
    return <MakeIntegration />
  }
  
  return <DeleteIntegration integration_id={integration.id} />

}

export default Configurations