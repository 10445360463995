import { useState, useEffect } from 'react'

import { useAuth } from '../contexts/AuthContext'
import useBackend from './useBackend'

function useUser() {

  const {logout, token} = useAuth()
  const {
    backend_get,
    backend_put
  } = useBackend()
  
  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)
  const [changed, setChanged] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleChangeName = (value) => {
    setChanged(true)
    setUser({ ...user, name: value })
  }

  const handleChangeEmail = (value) => {
    setChanged(true)
    setUser({ ...user, email: value })
  }

  async function handleSave () {
    setProcessing(true)
    backend_put(
      '/user',
      { name: user.name, email: user.email },
      token
    )
    .then(response => {
      console.log('backend_put response', response)

      if (response.ok) {
        setSuccess(true)
        setProcessing(false)
        setChanged(false)
        setTimeout(() => setSuccess(false), 1500)
      } else {
        // Error handing
        setProcessing(false)
        setChanged(false)
      }
    })
  }

  useEffect(() => {
    setLoading(true)

    backend_get('/user', token).then(response => {

      if (response.data === 'Network Error') {
        setError('Parece que não foi possível se conectar ao servidor. Tente novamente mais tarde.')
      } 
      
      if (!response.ok) {

        if (response.message = 'Invalid Authorization Token.') {
          logout()
        } else {
          setError('Houve um erro inesperado. Por favor, tente novamente.')
        }
        
      }
      
      else {
        setError(null)
        setUser({
          name: response.data.name,
          email: response.data.email,
        })
      }

      setLoading(false)

    })
  }, [])

  return {
    loading,
    processing,
    user,
    error,
    changed,
    success,
    handleChangeName,
    handleChangeEmail,
    handleSave,
  }

}

export default useUser