import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'

import Logo from './Logo'
//import Navigation from './Navigation'
import Settings from './Settings'
import Logout from './Logout'

function ResponsiveAppBar() {

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          
          <Logo />
          {/*<Navigation />*/}
          <Settings />
          <Logout />

        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;