import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from './contexts/AuthContext'

function PrivateRoute () {

	const { token } = useAuth()

	if (token === null) {
		return <Navigate to="/login" />
	}
		
	else {
		return <Outlet />
	}
		

}

export default PrivateRoute