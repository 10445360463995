import { useEffect, useState } from 'react'
import useBackend from './useBackend'
import { useAuth } from '../contexts/AuthContext'

function useMeli (props) {
  
  const {token} = useAuth()
  const {backend_get} = useBackend()

  const [integration, setIntegration] = useState(null)

  useEffect(() => {
    
    backend_get('/integrations', token)
    .then(integrations => {

      if (integrations.ok) {
        
        const integration = integrations.data.find(
          integration => (
            integration.type === 'meli'
            && parseInt(integration.store_id) === parseInt(props.store_id)
          )
        )

        if (!integration)
          setIntegration(false)
        else
          setIntegration(integration)
        
      }

    })

  }, [])

  return {
    integration
  }

}

export default useMeli