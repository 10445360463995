import Layout from "../../_layout"
import PageTitle from "../../util/PageTitle"
import { Alert, CircularProgress, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import useStores from '../../../../hooks/useStores'
import { useNavigate } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CreateStore from "./CreateStore"

function Page () {

  const {stores, error} = useStores()
  const navigate = useNavigate()

  return <Layout>
    
    <PageTitle
      title='Minhas Lojas'
      subtitle='Configure aqui as suas lojas.'
    />
    
    {!stores && !error
			? <CircularProgress />
			: Boolean(error)
				? error && <Alert variant="filled" severity="error">
						{error}
					</Alert>
				: <List>
            {stores.map(store => 
            <ListItem
              disablePadding
              key={store.id}
              onClick={() => navigate(`/painel/lojas/${store.id}`)}
              sx={{ mb: 1 }}
            >
              <ListItemButton>
                <ListItemText primary={store.name} />
                <ArrowForwardIosIcon />
              </ListItemButton>
            </ListItem>
            )}

            <CreateStore />
            
          </List>
		}

  </Layout>

}

export default Page