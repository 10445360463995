import { CircularProgress, Alert } from "@mui/material"

import useSlack from '../../../../hooks/useSlack'
import slack_configurations from './slack_configurations'
import { useStoreContext } from "../../../../contexts/StoreContext"
import Configuration from "./Configuration"

function Configurations () {
  
  const store = useStoreContext()
  const { channels, configurations } = useSlack({ store_id: store.id })

  return ( channels === null || configurations === null )
  ? <CircularProgress />
  : channels.error
    ? <Alert variant="filled" severity="error">{channels.error}</Alert>
    : <>

      {channels && slack_configurations.map((config, index) => <Configuration
        key={index}
        channels={channels}
        slack_configuration={config}
        user_configurations={configurations}
      />)}

    </>

}

export default Configurations