import { Typography } from "@mui/material"

function PageTitle ({title, subtitle}) {
    return <>
        <Typography
            variant="h5"
            component="div"
            sx={{ flexGrow: 1, mt: 2, mb: 1 }}
        >
            {title}
        </Typography>
        <Typography
            variant="p"
            component="div"
            sx={{ flexGrow: 1, mb: 3 }}
        >
            {subtitle}
        </Typography>
    </>
}

export default PageTitle