const data = [
  {
    id: 1,
    notification_type_id: 1,
    title: 'Novos Pedidos',
    description: 'Receba notificações sempre que fizer uma nova venda.'
  },
  {
    id: 2,
    notification_type_id: 2,
    title: 'Pedidos Entregues',
    description: 'Receba notificações sempre que uma venda for entregue.'
  },
  {
    id: 3,
    notification_type_id: 3,
    title: 'Novas Devoluções',
    description: 'Receba notificações sempre que uma nova devolução for aberta.'
  },
]

export default data