import { useEffect, useState } from "react";

import {useAuth} from '../contexts/AuthContext'
import useBackend from './useBackend'

function useStores () {

  const [error, setError] = useState(null)
  const [stores, setStores] = useState(null)

  const {logout, token} = useAuth()
  const {backend_get} = useBackend()

  useEffect(() => {
    backend_get('/stores', token).then(response => {

      if (response.data === 'Network Error') {
        setError('Parece que não foi possível se conectar ao servidor. Tente novamente mais tarde.')
      } else if(!response.ok) {
        if (response.message = 'Invalid Authorization Token.') {
          logout()
        }
      } else if(!Array.isArray(response.data)) {
        setError('Você ainda não tem lojas incluídas.')
      } else {
        setError(null)
        setStores(response.data)
      }

    })
  }, [])

  return {
    stores,
    error,
  }

}

export default useStores