import { Box, Button, Divider, Typography } from "@mui/material"
import useIntegration from "../../../../hooks/useIntegration"

function DeleteIntegration ({integration_id}) {

    const {
        error,
        success,
        deleteIntegration
    } = useIntegration()

    const handleClick = () => {
        deleteIntegration(integration_id)
    }

    return <Box sx={{ my: 2 }}>
        <Divider sx={{ mb: 2 }} />
        <Typography
            variant="p"
            component="div"
            sx={{ flexGrow: 1 }}
        >
            Sua loja está integrada ao Mercado Livre.
        </Typography>
        <Button variant='contained' color='error' onClick={handleClick}>
            Desfazer integração
        </Button>
    </Box>
  
}

export default DeleteIntegration