import Layout from "../../_layout"
import PageTitle from "../../util/PageTitle"
import Configurations from './Configurations'
import { useStoreContext } from "../../../../contexts/StoreContext"

function Page () {

  const store = useStoreContext()

  return <Layout>
    
    <PageTitle
      title={`Notificações Slack de ${store.name}`}
      subtitle='Configure aqui as suas notificações Slack.'
    />

    <Configurations />

  </Layout>

}

export default Page