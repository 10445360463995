import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/painel');
  };

  return (
    <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '10%' }}>
      <Box>
        <Typography variant="h5" component="div" gutterBottom>
          Página não encontrada
        </Typography>
        <Typography variant="body1" component="div" gutterBottom>
          A página que você está procurando não existe.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleGoHome} style={{ marginTop: '20px' }}>
          Voltar para o início
        </Button>
      </Box>
    </Container>
  );
};

export default NotFoundPage;