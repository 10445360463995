import { useNavigate } from 'react-router-dom'
import Layout from "../../_layout"
import PageTitle from "../../util/PageTitle"
import { CircularProgress, Divider, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {useStoreContext} from '../../../../contexts/StoreContext'
import DeleteStoreButton from './DeleteStoreButton'
import useSlack from '../../../../hooks/useSlack'

function Page () {
    
    const navigate = useNavigate()
    const store = useStoreContext()
    
    const slack = useSlack({ store_id: store.id })

    return <Layout>
        
        <PageTitle
            title={store.name}
            subtitle='Configure aqui as suas preferências para a loja.'
        />
        
        <Divider />

        <Typography
            variant="h6"
            component="div"
            sx={{ mt: 2, flexGrow: 1 }}
        >
            Mercado Livre
        </Typography>
        
        <List>
            <ListItem
                disablePadding
                onClick={() => navigate(`/painel/lojas/${store.id}/meli/integracao`)}
                sx={{ mb: 1 }}
            >
                <ListItemButton>
                    <ListItemText primary='Integração Mercado Livre' />
                    <ArrowForwardIosIcon />
                </ListItemButton>
            </ListItem>
        </List>

        <Divider />

        {slack.integration === null
        ? <CircularProgress />
        : <>
            <Typography
                variant="h6"
                component="div"
                sx={{ mt: 2, flexGrow: 1 }}
            >
                Slack
            </Typography>
            
            <List>
                <ListItem
                    disablePadding
                    sx={{ mb: 1 }}
                >
                    <ListItemButton
                        onClick={() => navigate(`/painel/lojas/${store.id}/slack/integracao`)}
                    >
                        <ListItemText primary='Integração Slack' />
                        <ArrowForwardIosIcon />
                    </ListItemButton>
                </ListItem>

                <ListItem
                    disablePadding
                    sx={{ mb: 1 }}
                    >
                    <ListItemButton
                        disabled={slack.integration === false}
                        onClick={() => navigate(`/painel/lojas/${store.id}/slack/configuracao`)}
                    >
                        <ListItemText primary='Notificações no Slack' />
                        <ArrowForwardIosIcon />
                    </ListItemButton>
                </ListItem>
            </List>
        </>}

        <Divider sx={{ mb: 3 }} />

        <DeleteStoreButton store_id={store.id} />

    </Layout>

}

export default Page;