import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuItem from '@mui/material/MenuItem';

const settings = [
  /*
  {label: 'Meu Perfil', route: '/painel/meu-perfil'},
  {label: 'Minhas Lojas', route: '/painel/minhas-lojas'},
  {label: 'Configurações Slack', route: '/painel/configuracoes-slack'}
  */
  {
    label: 'Meu Perfil',
    route: '/painel/perfil'
  },
  {
    label: 'Minhas Lojas',
    route: '/painel/lojas'
  },
];

function Settings () {

  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpen = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElUser(null);
  };

  return <Box sx={{ flexGrow: 0 }}>
    <IconButton
      onMouseOver={handleOpen}
      sx={{ p: 0 }}
    >
      <SettingsIcon sx={{ color: 'white' }} />
    </IconButton>
    <Menu
      sx={{ mt: '45px' }}
      id="menu-appbar"
      anchorEl={anchorElUser}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorElUser)}
      onClose={handleClose}
      MenuListProps={{ onMouseLeave: handleClose }}
    >
      {settings.map((setting) => 
        <MenuItem key={setting.route} onClick={() => navigate(setting.route)}>
          <Typography>{setting.label}</Typography>
        </MenuItem>
      )}
    </Menu>
  </Box>

}

export default Settings