import { useEffect, useState } from 'react'
import useBackend from './useBackend'
import { useAuth } from '../contexts/AuthContext'

function useSlack (props) {
  
  const {token} = useAuth()
  const {
    backend_get,
    backend_put,
    backend_delete
  } = useBackend()

  const [integration, setIntegration] = useState(null)
  const [channels, setChannels] = useState(null)
  const [configurations, setConfigurations] = useState(null)

  useEffect(() => {
    
    backend_get('/integrations', token)
    .then(integrations => {

      if (integrations.ok) {
        
        const integration = integrations.data.find(
          integration => (
            integration.type === 'slack'
            && parseInt(integration.store_id) === parseInt(props.store_id)
          )
        )

        if (!integration) {
          setChannels(null)
          setConfigurations(null)
          setIntegration(false)
        } else {

          setIntegration(integration)

          backend_get(`/slack/channels?store_id=${props.store_id}`, token)
          .then(response => {
            if (response.ok) {
              setChannels(response.data)
            }
            else {
              if (response.message === 'Internal server error.') {
                setChannels({error: 'Houve um erro interno e não foi possível listar os canais. Por favor, tente novamente ou contate o suporte.'})
              }
            }
          })

          backend_get(`/slack/configuration?store_id=${props.store_id}`, token)
          .then(response => {
            if (response.ok) {
              setConfigurations(response.data)
            }
          })

        }
        
      }

    })

  }, [])

  const updateConfiguration = async (store_id, notification_type_id, slack_channel_id) => {
    const response = await backend_put('/slack/configuration', {
      store_id,
      notification_type_id,
      slack_channel_id
    }, token)
    return response
  }

  const deleteConfiguration = async (store_id, notification_type_id) => {
    const response = await backend_delete(`/slack/configuration?store_id=${store_id}&notification_type_id=${notification_type_id}`, token)
    return response
  }

  return {
    integration,
    channels,
    configurations,
    updateConfiguration,
    deleteConfiguration,
  }

}

export default useSlack