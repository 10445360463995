import { Box, Button, Divider, Typography } from "@mui/material"
import {useAuth} from '../../../../contexts/AuthContext'
import useBackend from "../../../../hooks/useBackend"
import { useStoreContext } from "../../../../contexts/StoreContext"

function MakeIntegration () {

    const {token} = useAuth()
    const {backend_get} = useBackend()
    const store = useStoreContext()

    const handleClick = () => {
        backend_get(`slack/auth?store_id=${store.id}`, token).then(response => {
            if (response.ok) {
                window.location.href = response.data.url
            } else {
                alert('Houve um erro inesperado. Por favor, tente novamente.')
            }
        })
    }
  
    return <Box sx={{ my: 2 }}>
        <Divider sx={{ mb: 2 }} />
        <Typography
            variant="p"
            component="div"
            sx={{ flexGrow: 1 }}
        >
            Sua loja ainda não está integrada ao Slack.
        </Typography>
        <Button variant='contained' color='primary' onClick={handleClick}>
            Fazer integração
        </Button>
    </Box>
  
}

export default MakeIntegration