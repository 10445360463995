import React from 'react'
import ReactDOM from 'react-dom/client'

import { AuthProvider } from './contexts/AuthContext'

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))
const token = localStorage.getItem('utoken')

root.render(
  <AuthProvider token={token}>
    <App />
  </AuthProvider>
)