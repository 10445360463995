import Layout from "../../_layout" 
import PageTitle from "../../util/PageTitle"

function Page () {

  return <Layout>
    
    <PageTitle
      title='Bem vindo à Lyzard!'
      subtitle='Como nós vamos acelerar os seus resultados hoje?'
    />
      
  </Layout>

}

export default Page