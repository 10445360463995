import React from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useState } from 'react';
import useStore from '../../../../hooks/useStore';
import { useNavigate } from 'react-router-dom';

const DeleteStoreButton = ({store_id}) => {
  
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const {error, deleteStore} = useStore(store_id)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    setLoading(true)
    await deleteStore(store_id)
    setLoading(false)
    navigate('/painel/lojas')
  };

  if(error) {
    handleClickOpen()
  }

  return (
    <>
      <Button
        variant="contained"
        color="error"
        startIcon={<DeleteIcon />}
        onClick={handleClickOpen}
      >
        Excluir Loja
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Configrmar Exclusão da Loja"}
        </DialogTitle>
        <DialogContent>
          <p>Tem certeza de que deseja excluir esta loja?<br/>
          Todas as configurações da loja serão perdidas.</p>

          {error && <Alert variant="filled" severity="error">
            {error}
          </Alert>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            onClick={handleDelete}
            color="error"
            variant='contained'
            disabled={loading}
          >
            Excluir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteStoreButton;