import { Unstable_Grid2 as Grid } from "@mui/material"

function ContentWrapper ({children}) {

    return <Grid
        xs={12} xsOffset={0}
        md={6} mdOffset={3}
    >
        {children}
    </Grid>

}

export default ContentWrapper