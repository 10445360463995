import { Box, Typography } from "@mui/material"
import Layout from "../../_layout" 
import PageTitle from "../../util/PageTitle"

function Page () {

  return <Layout>
    
    <PageTitle
      title='Devoluções do Mercado Livre'
      subtitle='Aqui você acompanha as suas devoluções no Mercado Livre.'
    />

    <Box>
      <Typography
        variant="p"
        component="div"
        sx={{ flexGrow: 1, p: 2, background: '#eee', border: '1px solid #ddd' }}
      >
        Estamos trabalhando nesta ferramenta.
      </Typography>
    </Box>
    
  </Layout>

}

export default Page