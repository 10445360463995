import { useState } from 'react'
import useBackend from './useBackend'
import { useAuth } from '../contexts/AuthContext'

function useIntegration () {
  
    const {token} = useAuth()
    const {backend_delete} = useBackend()

    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(null)

    const deleteIntegration = async (integration_id) => {
        const response = await backend_delete(`/integrations/${integration_id}`, token)
        if (!response.ok) {
            setError('Houve um erro inesperado. Por favor, tente novamente.')
        } else {
            setSuccess(true)
        }
    }

    return {
        error,
        success,
        deleteIntegration,
    }

}

export default useIntegration