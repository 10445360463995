import axios from '../configs/axios.js'
import { useCallback } from 'react';

function useBackend () {

  // Abstração para adronização das respostas
  const response_promise = (promise) => {
    return promise
    .then(response => {
      return response.data
    })
    .catch(error => {
      return error.response
      ? error.response.data
      : { data: error.message }
    })
  }

  const backend_get = useCallback(async (relative_route, token = null) => {
    return response_promise(axios.get(relative_route, {
      headers: { Authorization: token }
    }))
  }, [])
  
  const backend_post = useCallback(async (relative_route, data, token = null) => {
    return response_promise(axios.post(relative_route, data, {
      headers: { Authorization: token }
    }))
  }, [])

  const backend_put = useCallback(async (relative_route, data, token = null) => {
    return response_promise(axios.put(relative_route, data, {
      headers: { Authorization: token }
    }))
  }, [])

  const backend_delete = useCallback(async (relative_route, token) => {
    return response_promise(axios.delete(relative_route, {
      headers: { Authorization: token }
    }))
  }, [])

  return {
    backend_get,
    backend_post,
    backend_delete,
    backend_put,
  }

}

export default useBackend