import React, { createContext, useContext } from 'react';

const StoreContext = createContext(null);

export const StoreProvider = ({ store, children }) => (
    <StoreContext.Provider value={store}>
        {children}
    </StoreContext.Provider>
);

export const useStoreContext = () => {
    const context = useContext(StoreContext);
    if (context === null) {
        throw new Error('useStoreContext must be used within a StoreProvider');
    }
    return context;
};