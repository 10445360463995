import React, { useState } from "react"
import { Alert, Button, ListItem, ListItemButton, ListItemText, TextField } from "@mui/material"
import AddIcon from '@mui/icons-material/Add'

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useBackend from "../../../../hooks/useBackend";
import { useAuth } from "../../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

function CreateStore () {

    const {token} = useAuth()
    const {backend_post} = useBackend()
    const navigate = useNavigate()
    
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(null)

    const handleOpen = () => {
        setOpen(true)
    }
    
    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const formJson = Object.fromEntries(formData.entries());
        const store_name = formJson.store_name;
        create(store_name);
    }

    const create = async (store_name) => {
        const response = await backend_post('/stores', { name: store_name }, token)
        
        if (response.data === 'Network Error') {
          setError('Parece que não foi possível se conectar ao servidor. Tente novamente mais tarde.')
        } else if (!response.ok) {
          setError(response.data)
        } else {
          setError(null)
          navigate(`/painel/lojas/${response.data.id}`)
        }
    }

    return <>

        <ListItem
            disablePadding
            key='create-store'
            onClick={handleOpen}
        >
            <ListItemButton>
                <ListItemText primary='Criar Loja' />
                <AddIcon />
            </ListItemButton>
        </ListItem>

        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit
            }}
        >
            <DialogTitle>Criar nova loja</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Criando uma nova loja, você pode integrar uma conta de Mercado Livre e também um Slack para receber as suas notificações.<br/><br/>
                    Vamos começar definindo o nome da sua loja.<br/>
                    Este é o nome que vai chegar nas suas notificações Slack.
                </DialogContentText>
                
                {Boolean(error) && error.field === 'name' && error.type === 'empty'
                && <Alert variant="filled" severity="error">
                    Preencha um nome para sua loja.
                </Alert>}
                
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    name="store_name"
                    label="Nome da Loja"
                    type="text"
                    fullWidth
                    variant="standard"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button type="submit" variant='contained'>Salvar e Configurar</Button>
            </DialogActions>
        </Dialog>

    </>
    
}

export default CreateStore