import { Typography } from "@mui/material"

function Logo () {
  return <Typography
    variant="h6"
    component="div"
    noWrap
    sx={{
      flexGrow: 1,
      fontFamily: "'PT Serif', serif",
      mr: 5
    }}
  >
    Lyzard
  </Typography>
}

export default Logo