import Layout from "../../_layout"

import AccountForm from './AccountForm'
import PageTitle from "../../util/PageTitle"

function Page () {

  return <Layout>
    
    <PageTitle
      title='Configurações de Usuário'
      subtitle='Configure aqui as suas informações pessoais.'
    />

    <AccountForm />
    
  </Layout>

}

export default Page