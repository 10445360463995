import { useEffect, useState } from "react";

import {useAuth} from '../contexts/AuthContext'
import useBackend from './useBackend'

function useStore (storeId) {

    const [error, setError] = useState(null)
    const [store, setStore] = useState(null)

    const {token} = useAuth()
    const {
        backend_get,
        backend_delete
    } = useBackend()

    useEffect(() => {
        backend_get(`/stores/${storeId}`, token).then(response => {
            if (!response.ok) {
                setError('Houve um erro inesperado. Tente novamente.')
            } else {
                setError(null)
                setStore(response.data)
            }
        })
    }, [])

    const deleteStore = async (store_id) => {
        const response = await backend_delete(`/stores/${store_id}`, token)

        if (!response.ok) {
            setError('Houve um erro inesperado. Tente novamente.')
        } else {
            setError(null)
            setStore(response.data)
        }
    }

    return {
        store,
        error,
        deleteStore
    }

}

export default useStore