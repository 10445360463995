import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import useBackend from '../../../../hooks/useBackend'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {`Copyright © Lyzard ${new Date().getFullYear()}.`}
    </Typography>
  );
}

export default function SignUp() {
  
  const navigate = useNavigate()
  const {backend_post} = useBackend()

  const [error, setError] = React.useState(null)

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form_data = new FormData(event.currentTarget)

    if (form_data.get('password') !== form_data.get('confirm_password')) {
      setError({
        name: 'ValidationError',
        type: 'invalid',
        field: 'confirm_password',
      })
    } else {
      const response = await backend_post('/user', {
        name: form_data.get('name'),
        email: form_data.get('email'),
        password: form_data.get('password'),
      })
  
      if (!response.ok) {
        setError(response.data)
      } else {
        setError(null)
        navigate('/login')
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" sx={{ flexGrow: 1, fontFamily: "'PT Serif', serif" }} >Lyzard</Typography>
        <Typography component="p" variant="p" sx={{ mt:2, textAlign: 'center' }}>
          Lembre-se de usar o seu melhor e-mail.<br/>
          É por ele que toda a comunicação<br/>com a Lyzard vai acontecer.
        </Typography>

        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="name"
                label="Nome"
                name="name"
                autoComplete="name"
                error={Boolean(error) && error.field === 'name'}
                helperText={
                  error && error.field === 'name'
                  && error.type === 'empty' && 'Informe seu nome.'
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                autoComplete="email"
                error={Boolean(error) && error.field === 'email'}
                helperText={
                  error && error.field === 'email'
                  ? error.type === 'empty'
                      ? 'Informe o E-mail.'
                      : error.type === 'invalid'
                        ? 'E-mail inválido.'
                        : ''
                  : ''
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                error={Boolean(error) && error.field === 'password'}
                helperText={
                  error && error.field === 'password'
                  ? error.type === 'empty'
                      ? 'Informe uma senha'
                      : error.type === 'invalid'
                        ? 'Senha inválida.'
                        : ''
                  : ''
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                fullWidth
                name="confirm_password"
                label="Confirmar Senha"
                type="password"
                id="confirm_password"
                error={Boolean(error) && error.field === 'confirm_password'}
                helperText={
                  error && error.field === 'confirm_password'
                  && 'As senhas não são iguais.'
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Registrar
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link href="/login" variant="body2">
                Já tem uma conta? Entre aqui.
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 5 }} />
    </Container>
  );
}