import { useParams } from "react-router-dom";
import useStore from "../../../../hooks/useStore";
import { Alert, CircularProgress } from "@mui/material";
import { StoreProvider } from '../../../../contexts/StoreContext';

function PageLoadStoreOrError ({ children }) {
  const { store_id } = useParams();
  const { store, error } = useStore(store_id);

  if (error) {
    return <Alert variant="filled" severity="error">{error}</Alert>;
  }

  if (!store) {
    return <CircularProgress />;
  }

  return (
    <StoreProvider store={store}>
      {
        error
        ? <Alert variant="filled" severity="error">{error}</Alert>
        : !store
          ? <CircularProgress />
          : children
      }
    </StoreProvider>
  );

}

export default PageLoadStoreOrError;