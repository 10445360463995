import TopBar from './Topbar'
import { Unstable_Grid2 as Grid, CssBaseline, Container } from '@mui/material';

import './style.css'
import ContentWrapper from './ContentWrapper';

function Layout ({children}) {

  return <>
    <CssBaseline /> {/* Global Reset */}
    <TopBar />
    <Container maxWidth="xl">
      <Grid container sx={{ mt: 2 }}>
        <ContentWrapper>
          {children}
        </ContentWrapper>
      </Grid>
    </Container>
  </>

}

export default Layout