import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { Alert, Box, CircularProgress } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import useUser from '../../../../hooks/useUser'

function AccountForm(){

	const user = useUser()

	return <Box sx={{ mt: 2 }}>
		
		{user.loading
			? <CircularProgress />
			: Boolean(user.error)
				? <>
					{user.error && <Alert variant="filled" severity="error">
						{user.error}
					</Alert>}
				</>
				: <>
					<TextField
						label="Nome"
						variant="standard"
						fullWidth
						value={user.user.name}
						onChange={(e) => user.handleChangeName(e.target.value)}
						sx={{ my: 1 }}
					/>

					<TextField
						label="E-mail"
						variant="standard"
						fullWidth
						value={user.user.email}
						onChange={(e) => user.handleChangeEmail(e.target.value)}
						sx={{ my: 1 }}
					/>

					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            			{user.changed && <Button
							variant='contained'
							onClick={user.handleSave}
							sx={{ mt: 1 }}
							disabled={user.processing}
						>
							Salvar alteração
						</Button>}
        			</Box>

					{user.success && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            			Salvo com sucesso.
					</Alert>}

					{user.error && <Alert severity="error">
						{user.error}
					</Alert>}
				</>
		}

	</Box>
}

export default AccountForm