import { Box, Button, Divider, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material"
import { useState } from "react"
import useSlack from "../../../../hooks/useSlack"
import { useStoreContext } from "../../../../contexts/StoreContext"
import Alert from '@mui/material/Alert'
import CheckIcon from '@mui/icons-material/Check'

function Configuration ({
    channels,
    slack_configuration,
    user_configurations
}) {
    
    const [value, setValue] = useState(() => {
        const not_receive = 'not_receive'

        if (user_configurations.length > 0) {
            const config = user_configurations.find(cfg => 
                cfg.notification_type_id === slack_configuration.notification_type_id
            )
            
            return !config
            ? not_receive
            : config.slack_channel_id
            
        }
        return not_receive
    })
    const [changed, setChanged] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const store = useStoreContext()
    const {
        updateConfiguration,
        deleteConfiguration
    } = useSlack(store.id)

    const handleChange = (e) => {
        setValue(e.target.value)
        setChanged(true)
    }

    const handleSave = async () => {
        
        const response = value === 'not_receive'
        ? await deleteConfiguration(
            store.id,
            slack_configuration.notification_type_id
        )
        : await updateConfiguration(
            store.id,
            slack_configuration.notification_type_id,
            value
        )
        
        if (response.ok) {
            setChanged(false)
            setSuccess(true)
            setTimeout(() => setSuccess(false), 1500)
        } else {
            setError('Houve um erro inesperado. Por favor, tente novamente.')
            setLoading(false)
        }
        setLoading(false)

    }

    return <>
        
        <Typography
            variant="h7"
            component="div"
            sx={{ flexGrow: 0, fontWeight: 'bold' }}
        >
            {slack_configuration.title}
        </Typography>
        <Typography
            variant="p"
            component="div"
            sx={{ flexGrow: 1, mb: 1 }}
        >
            {slack_configuration.description}
        </Typography>

        <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel size={'small'} id="demo-simple-select-label">Canal</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label="Canal"
                onChange={handleChange}
                size={'small'}
            >
                <MenuItem value='not_receive' key='not_receive'>Não receber notificações deste tipo.</MenuItem>
                {channels.map(channel => <MenuItem value={channel.id} key={channel.id}>#{channel.name}</MenuItem>)}
            </Select>
        </FormControl>

        {success && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Salvo com sucesso.
        </Alert>}

        {error && <Alert severity="error">
            {error}
        </Alert>}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            {changed && <Button
                variant='contained'
                onClick={handleSave}
                sx={{ mt: 1 }}
                disabled={loading}
            >
                Salvar alteração
            </Button>}
        </Box>

        <Divider sx={{ my: 2 }} />

    </>

}

export default Configuration