import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import {useAuth} from '../../../../contexts/AuthContext' 

function Logout () {

  const {logout} = useAuth()

  return <Box sx={{ flexGrow: 0 }}>
    <IconButton sx={{ ml:3, p: 0 }} onClick={logout} >
      <LogoutIcon sx={{ color: 'white' }} />
    </IconButton>
  </Box>

}

export default Logout