import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';

import {useAuth} from '../../../../contexts/AuthContext'
import useBackend from '../../../../hooks/useBackend'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {`Copyright © Lyzard ${new Date().getFullYear()}.`}
    </Typography>
  );
}

export default function SignIn() {
  
  const {token, login} = useAuth()
  const {backend_post} = useBackend()

  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault();
    const form_data = new FormData(event.currentTarget)
    const response = await backend_post('/user/login', {
      email: form_data.get('email'),
      password: form_data.get('password'),
    })

    if (!response.ok) {
      if (response.message === 'Internal server error.') {
        setError(response.message)
        setLoading(false)
      } else {
        setError(response.data)
        setLoading(false)
      }
    } else {
      setError(null)
      login(response.data.token)
      setLoading(false)
    }
  };

  return token
    ? <Navigate to="/painel" />
    : <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h3" sx={{ flexGrow: 1, fontFamily: "'PT Serif', serif" }} >Lyzard</Typography>
        <Typography component="p" variant="p" sx={{ mt:2, textAlign: 'center' }}>
          Acesse sua conta
        </Typography>

        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-mail"
            name="email"
            autoComplete="email"
            autoFocus
            error={Boolean(error) && error.field === 'email'}
            helperText={
              error && error.field === 'email'
              ? error.type === 'empty'
                  ? 'Informe o E-mail.'
                  : error.type === 'invalid'
                    ? 'E-mail inválido.'
                    : ''
              : ''
            }
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Senha"
            type="password"
            id="password"
            autoComplete="current-password"
            error={Boolean(error) && error.field === 'password'}
            helperText={
              error && error.field === 'password'
              && error.type === 'empty' && 'Informe a senha.'
            }
          />
          
          {Boolean(error) && error.field === 'credentials' && error.type === 'invalid'
          && <Alert variant="filled" severity="error">
            E-mail ou senha inválidos.
          </Alert>}

          {Boolean(error) && error === 'Network Error'
          && <Alert variant="filled" severity="error">
            Desculpe, mas parece que nosso servidor está offline. 
            Nossa equipe está trabalhando para resolver o problema o mais breve possível.
          </Alert>}

          {Boolean(error) && error === 'Internal server error.'
          && <Alert variant="filled" severity="error">
            Houve um erro interno. Tente novamente, por favor.
            Se o problema persistir, por favor, entre em contato com o suporte.
          </Alert>}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            disabled={loading}
          >
            Entrar
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/registro" variant="body2">
                Não tem uma conta? Cadastre-se.
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
}