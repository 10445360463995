import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from './PrivateRoute'

// Errors
import Panel404 from './components/panel/pages/404'
import Site404 from './components/site/pages/404'

// // // Auth
import PanelLogin from './components/panel/pages/login'
import PanelRegister from './components/panel/pages/register'
import PanelResetPassword from './components/panel/pages/reset_password'

// // // Panel

import PanelDashboard from './components/panel/pages/dashboard'
import PanelMeliReturns from './components/panel/pages/meli_returns'
// // // Panel // Settings
import PanelMyProfile from './components/panel/pages/my_profile'
import PanelMyStores from './components/panel/pages/my_stores'
import PanelMyStore from './components/panel/pages/my_store'
import PanelMeliIntegration from './components/panel/pages/meli_integration'
import PanelSlackIntegration from './components/panel/pages/slack_integration'
import PanelSlackSettings from './components/panel/pages/slack_settings'
import PageLoadStoreOrError from "./components/panel/util/PageLoadStoreOrError"

export default function App() {

  return <BrowserRouter>
    
    <Routes>
      
      {/* Site 404 Error */}
      <Route path="/*" element={<Site404 />} />

      {/* Auth */}
      <Route path="/login" element={<PanelLogin />} />
      <Route path="/registro" element={<PanelRegister />} />
      <Route path="/recuperar-senha" element={<PanelResetPassword />} />
      
      <Route path='/' element={<PrivateRoute />}>
        
        {/* Panel 404 Error */}
        <Route path="/painel/*" element={<Panel404 />} />
        
        {/* Panel Root */}
        <Route
          path="/"
          element={<PanelDashboard />}
        />
        
        {/* Panel Home */}
        <Route
          path="/painel"
          element={<PanelDashboard />}
        />

        {/* Panel Settings */}
        <Route
          path="/painel/perfil"
          element={<PanelMyProfile />}
        />
        <Route
          path="/painel/lojas"
          element={<PanelMyStores />}
        />
        <Route
          path="/painel/lojas/:store_id"
          element={<PageLoadStoreOrError>
            <PanelMyStore />
          </PageLoadStoreOrError>}
        />
        <Route
          path="/painel/lojas/:store_id/meli/integracao"
          element={<PageLoadStoreOrError>
            <PanelMeliIntegration />
          </PageLoadStoreOrError>}
        />
        <Route
          path="/painel/lojas/:store_id/slack/integracao"
          element={<PageLoadStoreOrError>
            <PanelSlackIntegration />
          </PageLoadStoreOrError>}
        />
        <Route
          path="/painel/lojas/:store_id/slack/configuracao"
          element={<PageLoadStoreOrError>
            <PanelSlackSettings />
          </PageLoadStoreOrError>}
        />

        {/* Panel Returns */}
        <Route
          path="/painel/devolucoes-meli"
          element={<PanelMeliReturns />}
        />

      </Route>
      
    </Routes>

  </BrowserRouter>

}